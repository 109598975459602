import styled from "styled-components";
import { rem } from 'polished';


// top section

export const SectionTopHeading = styled.h1`
    font-size: ${rem(48)};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    line-height: ${rem(66)};
    text-align: center;
    color: ${(props) => props.theme.colors.tertiary};
    padding-top: ${rem(80)};
    padding-bottom: ${rem(16)};


    @media (max-width: 768px) {
        font-weight: ${(props) => props.theme.fontWeights.normal};
        font-size: ${rem(36)};
        line-height: ${rem(48)};
        padding-top: ${rem(20)};
        padding-bottom: ${rem(16)};
  }
    
`;

export const SectionTopText = styled.p`
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(18)};
    line-height: ${rem(30)};
    color: ${(props) => props.theme.colors.tertiary};
    text-align: center;
    opacity: 0.7;

    @media (max-width: 768px) {
        font-size: ${rem(16)};
        padding-bottom: ${rem(20)};
    }
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
        width: ${rem(80)};
        height: ${rem(80)};
    }

  @media (max-width: 768px) {
        display: flex;
        justify-content: flex-start;
        align-content: stretch;
  }
`;
export const SectionTopWrapper = styled.div`
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    
    @media (max-width: 768px) {
        display: flex;
        justify-content: flex-start;
        padding-top: ${rem(48)};
        padding-bottom: ${rem(48)};
  }
`;

export const Cardtop = styled.div`
	display: flex;
    flex-direction: row;
    gap: ${rem(80)};
    margin-top: ${rem(80)};
    padding-top: ${rem(20)};
    padding-bottom: ${rem(80)};
    
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding-top: ${rem(10)};
        padding-bottom: ${rem(10)};
    }
`;
export const CardHeading = styled.h4`
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${rem(24)};
    line-height: ${rem(36)};
    margin-bottom: ${rem(16)};
    color: ${(props) => props.theme.colors.tertiary};

    @media (max-width: 768px) {
        font-size: ${rem(20)};
        line-height: ${rem(30)};
    }
`;
export const CardText = styled.p`
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    margin: 0;
    color: ${(props) => props.theme.colors.tertiary};
    opacity: 0.7;
`;

// section one
export const SectionOneWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: ${rem(80)};
    margin-top: ${rem(80)};
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};

    p {
        opacity: 0.7;
    }
    
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding-top: ${rem(35)};
        padding-bottom: ${rem(40)};
    }
    
`;

export const SectionOneHeading = styled.h2`
    font-size: ${rem(32)};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    line-height: ${rem(46)};
    margin: 0;
    text-align: center;

    @media (max-width: 768px) {
        font-size: ${rem(26)};
        line-height: ${rem(39)};
    }
`;

export const SectionOneText = styled.p`
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    text-align: center;
    margin: 0;
    padding-top: ${rem(18)}; 

    @media (max-width: 768px) {
        font-size: ${rem(18)};
        line-height: ${rem(30)};
        padding-top: ${rem(16)}; 
        padding-bottom: ${rem(30)}; 
    }

`;
export const SectionOneImage = styled.div`
   .image2 {
        display: none;
            @media (max-width: 768px) {
                width: 30vh;
                display: block;
                align-items: center;
                margin: 0 auto;
                padding-top: ${rem(48)};
                padding-bottom: ${rem(25)};
}
    }
    .image1 {
        padding-top: ${rem(80)};
        padding-bottom: ${rem(80)};
        
            @media (max-width: 768px) {
                display: none;
    }
    }
`;


// section two
export const WrapperSectionTwo = styled.div`
    background: ${(props) => props.theme.colors.white};
    display: flex;
    justify-content: center;
    
    @media (max-width: 768px) {
          display: none;
        }
    @media (max-width: 768px) {
          display: none;
        }
`;

export const SectionTwoLeft = styled.div`
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: baseline;
	align-content: stretch;

`;

export const SectionTwoRight = styled.div`
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
    padding-top: ${rem(30)};
`;

export const SectionTwoHeading = styled.h4`
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${rem(32)};
    line-height: ${rem(46)};
    padding-bottom: ${rem(16)};
    padding-right: ${rem(80)};
`;

export const SectionTwoLink = styled.p`
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: ${rem(18)};
    line-height: ${rem(30)};
    text-decoration-line: underline;
    color: ${(props) => props.theme.colors.primary};
`;

export const SectionTwoParagraph = styled.p`
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    justify-content: start;
    color: ${(props) => props.theme.colors.tertiary};
    opacity: 0.7;
    padding-left: ${rem(21)};
    padding-bottom: ${rem(24)};
    
`;

export const ContentsWrapper = styled.div`
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
`;

// section 3.

export const PhotoLeft = styled.div`
    position: relative;
    top: 0;
    left: 0;
    

   img {
    width: ${rem(250)};
    height: ${rem(250)};
    padding-top: ${rem(15)};
    position: relative;
    top: 0;
    left: 0;
    
  }
`;
export const PhotoRight = styled.div`
    position: relative;
    top: 0;
    left: 0;
    

   img {
    width: ${rem(250)};
    height: ${rem(250)};
    padding-top: ${rem(15)};
    position: relative;
    top: 0;
    left: 0;
    
  }
`;

export const BgImageSec3 = styled.div`
    position: absolute;
    top: ${rem(30)};
    left: ${rem(30)};
`;

export const CardContainerSec3 = styled.div`
    display: flex;
    align-items: center;
    gap: ${rem(96)};

    @media (max-width: 768px) {
        flex-direction: column;
    }

    :nth-of-type(even) {
        flex-direction: row-reverse;

        @media (max-width: 768px) {
        flex-direction: column;
        }
    }

    img {
        width: 300px;
        height: 254px;

        @media (max-width: 768px) {
        width: 327px;
        height: 289px;
        }
    }

`;

export const CardHeadingSec3 = styled.h3`
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${rem(24)};
    line-height: ${rem(36)};
    margin-bottom: ${rem(16)};

    @media (max-width: 768px) {
        font-size: ${rem(20)};
        line-height: ${rem(30)};
    }
`;

export const CardTextSec3 = styled.p`
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    margin: 0;

`;

export const Grid = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(80)};
    margin-top: ${rem(80)};
`;

export const ContentWrapperSec3 = styled.div`
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
    color: ${(props) => props.theme.colors.tertiary};

    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        opacity: 0.7;
    }
`;

export const SectionHeading = styled.h2`
    font-size: ${rem(32)};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    line-height: ${rem(46)};
    margin: 0;
    text-align: center;

    @media (max-width: 768px) {
        font-size: ${rem(26)};
        line-height: ${rem(39)};
    }
`;

export const CardContainerImages = styled.div`
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
	align-content: flex-start;
    position: relative;

    .image1 {
        position: relative;
        top: 0;
        left: 0;
    
}
    .image2 {
        position: absolute;
        top: ${rem(25)};
        left: ${rem(25)};
        border-radius: ${rem(30)};
        
    }
`;


export const CardContainerContent = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${rem(24)};
    line-height: ${rem(36)};
    margin-bottom: ${rem(16)};
`;

