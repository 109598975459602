import React from 'react';
import * as Styles from '../styles';

import SchemeHow from 'images/SchemeHow.png';
import SchemeVertical from 'images/SchemeVertical.png';

import Section from 'components/Section';
import Box from 'components/Box';

import { Trans, useTranslation } from 'react-i18next';

const Section1 = () => {
  const { t } = useTranslation();

  return (
    <Styles.SectionOneWrapper>
      <Section>
        <Box>
          <Styles.SectionOneHeading>
            <Trans i18nKey={'how_it_works.Facilitate.title.title'} />
          </Styles.SectionOneHeading>
          <Styles.SectionOneText>
            {t('how_it_works.Facilitate.title.description')}
          </Styles.SectionOneText>
          <Styles.SectionOneImage>
            <img className="image1" src={SchemeHow} alt="" aria-hidden="true" />
            <img
              className="image2"
              src={SchemeVertical}
              alt=""
              aria-hidden="true"
            />
          </Styles.SectionOneImage>
        </Box>
      </Section>
    </Styles.SectionOneWrapper>
  );
};

export default Section1;
