import React from 'react';
import * as Styles from "../styles";

import PageHero from "components/PageHero"
import Box from 'components/Box';

import ReallyEasyIcon from "images/ReallyEasyIcon.svg";
import GetSteadyIcon from "images/GetSteadyIcon.svg";
import WhatMakesIcon from "images/WhatMakesIcon.svg";

import { Trans, useTranslation } from 'react-i18next';
import NavigationColor from 'utils/constants/NavigationColors';


const CardsSectionTop = [
  {
    title: 'how_it_works.ATF.easy.title',
    text: 'how_it_works.ATF.easy.description',
    icon: ReallyEasyIcon,
  },
  {
    title: 'how_it_works.ATF.returns.title',
    text: 'how_it_works.ATF.returns.description',
    icon: GetSteadyIcon,
  },
  {
    title: 'how_it_works.ATF.Group_198.title',
    text: 'how_it_works.ATF.Group_198.description',
    icon: WhatMakesIcon,
  },
];

interface CardProps {
  element: {
  title: string;
  text: string;
  icon: any;
  };
}

const Card = ({element} : CardProps)=> {
  const { title, text, icon } = element;

  const {t} = useTranslation();
  
  return (
      <Styles.CardContainer>
        <img src={icon} alt="" aria-hidden="true" />
        <Styles.CardHeading>
          <Trans i18nKey={title} /> 
        </Styles.CardHeading>
        <Styles.CardText>
          {t(text)} 
        </Styles.CardText>
      </Styles.CardContainer>
  );
};

const Header = () => {
  const {t} = useTranslation();
  
  return (
    <PageHero navigationColorTheme={NavigationColor.DARK}>
      <Styles.SectionTopWrapper>
        <Box>
          <Styles.SectionTopHeading>
            <Trans i18nKey={'home.Footer.How_it_works'} />
          </Styles.SectionTopHeading>
          <Styles.SectionTopText>
            {t('how_it_works.ATF.Description')}
          </Styles.SectionTopText>
            <Styles.Cardtop>
              {CardsSectionTop.map((item) => (
                <Card element={item} key={item.text} />
            ))}
            </Styles.Cardtop>
        </Box>
      </Styles.SectionTopWrapper>
    </PageHero>
  );
};

export default Header;