import React from 'react';
import * as Styles from "../styles";

import Box from 'components/Box';
import Section from 'components/Section';

import FirstSec3 from 'images/FirstSec3.svg';
import SecondSec3 from 'images/SecondSec3.svg';
import ThirdSec3 from 'images/ThirdSec3.svg';
import FourthSec3 from 'images/FourthSec3.svg';
import FifthSec3 from 'images/FifthSec3.svg';
import SixthSec3 from 'images/SixthSec3.svg';
import ImageBgSec3 from 'images/ImageBgSec3.svg';

import { Trans, useTranslation } from 'react-i18next';



const HowItWorksCards = [
    {
        title: 'how_it_works.trust.emi.tile',
        text: 'how_it_works.trust.emi.description',
        icon: FirstSec3,
        background: ImageBgSec3,
    },
    {
        title: 'how_it_works.trust.compliance.title',
        text: 'how_it_works.trust.compliance.description',
        icon: SecondSec3,
        background: ImageBgSec3,
    },
    {
        title: 'how_it_works.trust.protection.title',
        text: 'how_it_works.trust.protection.description',
        icon: ThirdSec3,
        background: ImageBgSec3,
    },
    {
        title: 'home.USPs.notifications.title',
        text: 'how_it_works.trust.Group_179.notifications.Title',
        icon: FourthSec3,
        background: ImageBgSec3,
    },
    {
        title: 'how_it_works.trust.diversification.diversification.title',
        text: 'how_it_works.trust.diversification.diversification.description',
        icon: FifthSec3,
        background: ImageBgSec3,
    },
    {
        title: 'how_it_works.trust.Group_181.due_diligence.due_diligence',
        text: 'how_it_works.trust.Group_181.due_diligence.description',
        icon: SixthSec3,
        background: ImageBgSec3,
    },

]

interface CardProps {
    element: {
    title: string;
    text: string;
    icon: any;
    background: any,
};
}


const Card = ({element}: CardProps) => {
    const {t} = useTranslation();

    const { title, text, icon, background } = element;

    return (
        <Styles.CardContainerSec3>
            <Styles.CardContainerImages>
                <img className="image1" src={t(background)} alt="" aria-hidden="true" />
                <img className="image2" src={t(icon)} alt="" aria-hidden="true" />
            </Styles.CardContainerImages>
            <Styles.CardContainerContent>
                <Styles.CardHeadingSec3>
                    <Trans i18nKey={title} /> 
                </Styles.CardHeadingSec3>
                <Styles.CardTextSec3> {t(text)} </Styles.CardTextSec3>
            </Styles.CardContainerContent>    
        </Styles.CardContainerSec3>
    );
};

const Section3 = () => {
    const {t} = useTranslation();

  return (
    <Styles.ContentWrapperSec3>
        <Section>
            <Box>
            <Styles.SectionHeading>
                <Trans i18nKey={'how_it_works.trust.title'} />
            </Styles.SectionHeading>
                <Styles.Grid>
                    {HowItWorksCards.map((item) => (
                        <Card element={item} key={item.title} />
                    ))}
                </Styles.Grid>
            </Box>
        </Section>
    </Styles.ContentWrapperSec3>
  );
};

export default Section3;