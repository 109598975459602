import React from 'react';
import * as Styles from "../styles";
import { Link } from 'gatsby'

import Box from 'components/Box';

import { Trans, useTranslation } from 'react-i18next';


const Section2 = () => {
  const {t} = useTranslation();

  return (
    <Styles.WrapperSectionTwo>
      <Box>
        <Styles.ContentsWrapper>
          <Styles.SectionTwoLeft>
              <Styles.SectionTwoHeading>
                <Trans i18nKey={'how_it_works.Security.title'} />
              </Styles.SectionTwoHeading>
              <Styles.SectionTwoLink>
                <a href="/security">
                {t('how_it_works.Security.security_link')}
                </a>
              </Styles.SectionTwoLink>
          </Styles.SectionTwoLeft>
          <Styles.SectionTwoRight>
              <Styles.SectionTwoParagraph>
                {t('how_it_works.Security.disclaimer_1')}
              </Styles.SectionTwoParagraph>
              <Styles.SectionTwoParagraph>
                {t('how_it_works.Security.disclaimer_2')}
              </Styles.SectionTwoParagraph>
              <Styles.SectionTwoParagraph>
                {t('how_it_works.Security.disclaimer_3')}
              </Styles.SectionTwoParagraph>
          </Styles.SectionTwoRight>
        </Styles.ContentsWrapper>
      </Box>
    </Styles.WrapperSectionTwo>
  );
};

export default Section2;