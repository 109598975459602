import React from 'react';

import DirectSignup from 'components/DirectSignup';

import Header from './components/Header';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';

const HowItWorks = () => {
  return (
    <>
      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      <DirectSignup />
    </>
  );
};

export default HowItWorks;
